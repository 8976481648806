import decode from 'jwt-decode';
import { store } from '../stores';
import { ITokenPayload } from '../types';
import faq from './../data/faq.json';
import survey from './../data/survey.json';
import termsAndConditions from './../data/termsAndConditions.json';
import reportDo from './../data/reportDo.json';
import reportRemember from './../data/reportRemember.json';
import ideas from './../data/ideas.json';
import landing from './../data/landing.json';
import whatIsLucy from './../data/whatIsLucy.json';
import loginInfo from './../data/loginInfo.json';
import resultPage from './../data/resultPage.json';
import reportHeader from './../data/reportHeader.json';

// all regex
export const emailRegex = /^([a-z\d._-]+)@([a-z\d_-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i;
export const lowercaseRegex = /(?=.*[a-z])/;
export const uppercaseRegex = /(?=.*[A-Z])/;
export const numericRegex = /(?=.*[0-9])/;
export const specialCharRegex = /(?=.*[\^$*.[\]{}()?“!@#%&/,><’:;|_~`/-])/;
export const durationRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/;

// access token
export const setAccessToken = (token: string) => {
  window.localStorage.setItem('access-token', token);
};
export const removeAccessToken = () => {
  window.localStorage.removeItem('access-token');
};
export const getAccessToken = (): string | null => {
  let accessToken: string | null = null;
  if (typeof window !== undefined && window.localStorage.getItem('access-token')) {
    accessToken = window.localStorage.getItem('access-token');
    return accessToken;
  } else {
    removeAccessToken();
  }
  return accessToken;
};

// Auth checker
export const isAuthenticate = () => (store.getState().userReducer.user ? true : false);

// token expiry check
export const isTokenExpired = (token: string): boolean => {
  try {
    const decoded = decode(token) as ITokenPayload;
    if (decoded.exp > Date.now() / 1000) {
      return false;
    } else return true;
  } catch (err) {
    return true;
  }
};

// ? FAQ Data
export interface IFAQData {
  title: string | number;
  body: string | number | JSX.Element;
}
export const faqData: IFAQData[] = faq.data;

// ? Survey Data
export interface ISurveyData {
  version: string;
  data: { question: string; options?: string[]; hasOther?: boolean; type: string }[];
}
export const surveysData: ISurveyData = survey;

// ? Terms and Condition Data
export interface ITCData {
  title: string | number;
  body: string[];
}
export const TermsAndConditionsData: ITCData[] = termsAndConditions.data;

// ? Report Do Data
export const ReportDoData: string[] = reportDo.data;

// ? Report Remember Data
export const ReportRememberData: string[] = reportRemember.data;

// ? LUCY™ IDEAS REPORT Data
export interface IIdeasData {
  title: string;
  name: string;
  image: string;
}
export const IdeasData: IIdeasData[] = ideas.data;

// ? Lucy ILanding Data
export interface ILandingData {
  title: string;
  body: string;
}
export const LandingData: ILandingData = landing.data;

// ? what is lucy Data
export interface IWhatIsLucyData {
  body: string[];
  quote: IIdeasData;
}
export const WhatIsLucyData: IWhatIsLucyData = whatIsLucy.data;

// ? Login info Data
export const LoginInfoData: string = loginInfo.data;

// ? result page Data
export interface IResultPageData {
  main: { title: string };
  secondary: { title: string; body: string };
}
export const ResultPageData: IResultPageData = resultPage.data;

// ? LUCYidea's report page Data
export interface IReportHeaderData {
  title: string;
  body: string;
}
export const ReportHeaderData: IReportHeaderData = reportHeader.data;
