import { IPrecedentByQuestionsResponse } from '../../types';
import { IQuestion } from './Reducer';
import { QUESTION_TYPES } from './Types';

export const addMcAction = (payload: IQuestion['tId']) => {
  return { type: QUESTION_TYPES.ADD_MC, payload };
};
export const addDcAction = (payload: IQuestion[]) => {
  return { type: QUESTION_TYPES.ADD_DC, payload };
};
export const addDaAction = (payload: IQuestion[]) => {
  return { type: QUESTION_TYPES.ADD_DA, payload };
};
export const addPrecedentsAction = (payload: IPrecedentByQuestionsResponse[]) => {
  return { type: QUESTION_TYPES.ADD_PRECEDENTS, payload };
};
export const selectPrecedentAction = (payload: IPrecedentByQuestionsResponse) => {
  return { type: QUESTION_TYPES.SELECT_PRECEDENT, payload };
};
export const resetQuestionsAction = () => {
  return { type: QUESTION_TYPES.RESET_QUESTIONS };
};
