import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import decode from 'jwt-decode';
import { Loader } from './components/Loader';
import { LucyApi } from './config';
import ReportPage from './pages/report';
import { ITokenPayload } from './types';
import { getAccessToken, isAuthenticate, isTokenExpired } from './utils';
import { useDispatch } from 'react-redux';
import { logoutAction, userDetailsAction } from './stores/user/Actions';
import { LucyToaster } from './components/Toaster';
import { resetQuestionsAction } from './stores/questions/Actions';

const LandingPage = lazy(() => import('./pages/landing'));
const LoginPage = lazy(() => import('./pages/login'));
const RegistrationPage = lazy(() => import('./pages/registration'));
const ContactPage = lazy(() => import('./pages/contact'));
const PricingPage = lazy(() => import('./pages/pricing'));
const TermsAndConditions = lazy(() => import('./pages/termsAndConditions'));
const FAQ = lazy(() => import('./pages/faq'));
const SecondaryApp = lazy(() => import('./SecondaryApp'));
const forgotPassword = lazy(() => import('./pages/forgotPassword'));

export const PrivateRoute = ({ children, ...rest }: RouteProps) => (
  <Route
    {...rest}
    render={({ location }) =>
      isAuthenticate() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: { pathname: location.pathname } },
          }}
        />
      )
    }
  />
);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function getUser() {
      const token = getAccessToken();
      if (token && !isTokenExpired(token)) {
        try {
          const decodedPayload = decode(token) as ITokenPayload;

          const res = await LucyApi.get(`/user/${decodedPayload.uId}`, {
            headers: {
              Authorization: token,
            },
          });
          dispatch(userDetailsAction(res.data));
        } catch (error) {
          dispatch(logoutAction());
          dispatch(resetQuestionsAction());
          LucyToaster('Please login, token expired.', 'danger');
        }
      } else {
        dispatch(logoutAction());
        dispatch(resetQuestionsAction());
      }
    }

    getUser();
  }, [dispatch]);
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (isAuthenticate() ? <Redirect to="/business-challenges" /> : <LandingPage />)}
          />
          <Route
            exact
            path="/login"
            render={() => (isAuthenticate() ? <Redirect to="/business-challenges" /> : <LoginPage />)}
          />
          <Route
            exact
            path="/register"
            render={() => (isAuthenticate() ? <Redirect to="/business-challenges" /> : <RegistrationPage />)}
          />
          <Route
            exact
            path="/contact"
            render={() => (isAuthenticate() ? <Redirect to="/business-challenges" /> : <ContactPage />)}
          />
          <Route
            exact
            path="/pricing"
            render={() => (isAuthenticate() ? <Redirect to="/business-challenges" /> : <PricingPage />)}
          />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/forgot-password" component={forgotPassword} />
          <PrivateRoute exact path="/business-challenges/:tId/report">
            <ReportPage />
          </PrivateRoute>
          <PrivateRoute path="*">
            <SecondaryApp />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
