import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import LucyIcon from '../../components/icons';
import { IPrecedentByQuestionsResponse, ITermResponse } from '../../types';
import { LucyApi, s3FileUrl } from '../../config';
import { useSelector } from 'react-redux';
import { IReducer } from '../../stores/IndexReducer';
import { IQuestion } from '../../stores/questions/Reducer';
import { ReportDoData, getAccessToken, ReportRememberData, IdeasData, ReportHeaderData } from '../../utils';

interface params {
  tId: string;
}
const fetchQuestion = async (tId: string) => {
  const response = await LucyApi.get(`/term_data/${tId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const fetchPrecedentsCountByQuestions = async ({
  dcQuestion,
  daQuestion,
}: {
  dcQuestion: IQuestion[];
  daQuestion: IQuestion[];
}) => {
  let arr: IPrecedentByQuestionsResponse[] = [];
  for await (const dc of dcQuestion) {
    const currentSelectedDAQuestion = daQuestion.filter((v) => v.parentId.toString() === dc.tId.toString());
    let tId = currentSelectedDAQuestion.map((v) => v.tId);
    if (tId.length === 1) {
      tId.push(tId[0]);
    }
    const response = await LucyApi.post(
      `/client/precedent`,
      { tId },
      {
        headers: {
          Authorization: getAccessToken(),
        },
      },
    );
    arr = [...arr, ...response.data.data];
  }
  return arr.length;
};

const ReportPage = () => {
  const history = useHistory();
  const params = useParams<params>();
  const { dcQuestion, daQuestion, precedents } = useSelector((state: IReducer) => {
    return {
      dcQuestion: state.questionReducer.DC || [],
      daQuestion: state.questionReducer.DA || [],
      precedents: state.questionReducer.precedents || [],
    };
  });

  const mcQuestionMethods = useQuery<ITermResponse, Error>(
    ['term', params.tId],
    async () => {
      return await fetchQuestion(params.tId);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const precedentsMethods = useQuery<number, Error>(
    ['precedents-report', { dcQuestion, daQuestion }],
    async () => {
      return await fetchPrecedentsCountByQuestions({ dcQuestion, daQuestion });
    },
    {
      refetchOnWindowFocus: false,
      enabled: daQuestion.length > 0,
    },
  );

  const handleSubmit = () => {
    history.push(`/business-challenges/${params.tId}/survey`);
  };

  return (
    <div>
      <div>
        <div className="text-white bg-primary-400 px-6 sm:px-10 pt-12">
          <div className="flex justify-between flex-wrap items-center mb-12">
            <Link to="/" className="">
              <img src="https://lucy-resources.s3.amazonaws.com/assets/lucy.png" alt="lucy" />
            </Link>
            <div className="flex space-x-8 mt-5 mr-5 ">
              <button
                onClick={handleSubmit}
                className="rounded-xl px-8 py-2 font-semibold text-black bg-secondary-400 focus:outline-none"
              >
                Take Survey
              </button>
              <button
                className="rounded-xl mt-1 px-8 py-3 font-semibold text-primary-400 bg-white opacity-70 cursor-not-allowed"
                disabled
              >
                Export as PDF
              </button>
            </div>
          </div>
          <div className="rounded-t-2xl p-14 bg-white bg-opacity-10">
            <h1 className="text-2xl py-2">{ReportHeaderData.title}</h1>
            <p className="py-2">{format(new Date(), 'hh:mm a, do MMM, yyyy')}</p>
            <p className="font-xs py-2">{ReportHeaderData.body}</p>
          </div>
        </div>
        <div className="p-6 sm:p-10 space-y-6">
          <p className="text-primary-400 text-3xl font-semibold mb-10">Executive summary</p>
          <div className="bg-white rounded-3xl shadow-md space-y-4 p-10">
            <p className="text-lg font-semibold mb-4">{mcQuestionMethods.data?.tTitle}</p>
            {dcQuestion.map((v) => (
              <div key={v.tId} className="flex items-start">
                <LucyIcon name="checkbox-checked" className="mt-1 h-4" />
                <p className="ml-4 text-black font-normal w-11/12">{v.tTitle}</p>
              </div>
            ))}
          </div>
          <div className="bg-white rounded-3xl shadow-md space-y-4 p-10">
            <p className="text-lg font-semibold mb-4">{daQuestion.length} Questions</p>
            {daQuestion.map((v) => (
              <div key={v.tId} className="flex items-start">
                <LucyIcon name="checkbox-checked" className="mt-1 h-4" />
                <p className="ml-4 text-black font-normal w-11/12">{v.tTitle}</p>
              </div>
            ))}
          </div>

          <div className="bg-white rounded-3xl shadow-md space-y-4 p-10">
            {precedentsMethods.data && (
              <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="text-lg font-semibold mb-4">
                {precedents.length}/{precedentsMethods.data} Precedents saved in the report
              </motion.p>
            )}
            {precedents
              .sort((a, b) => {
                return (a.precedentTitle || '').localeCompare(b.precedentTitle || '', 'en', { sensitivity: 'base' });
              })
              .map((v) => (
                <div key={v.pId} className="flex space-x-6">
                  <div className="h-14 w-14 rounded-sm overflow-hidden flex-shrink-0">
                    <img className="w-full h-full object-contain" src={`${s3FileUrl}${v.logo?.url}`} alt={v.actor} />
                  </div>
                  <div className="">
                    <p className="font-semibold text-lg">{v.precedentTitle}</p>
                    <p className="font-semibold text-sm text-gray-500">{v.actor}</p>
                  </div>
                </div>
              ))}
          </div>

          <p className="text-primary-400 text-3xl font-semibold mb-10">How to use this report</p>

          <div className="bg-white rounded-3xl shadow-md space-y-4 p-10">
            <div className="">
              <p className="text-lg font-semibold mb-4">Do</p>
              {ReportDoData.map((v, i) => (
                <div key={i} className="flex items-start space-x-3">
                  <span className="h-2 w-2 bg-black rounded-full flex-shrink-0 mt-2" />
                  <p className="font-normal">{v}</p>
                </div>
              ))}

              <p className="text-lg pt-10 font-semibold mb-4">Remember</p>
              {ReportRememberData.map((v, i) => (
                <div key={i} className="flex items-start space-x-3">
                  <span className="h-2 w-2 bg-black rounded-full flex-shrink-0 mt-2" />
                  <p className="font-normal">{v}</p>
                </div>
              ))}
            </div>
          </div>

          <p className="text-primary-400 text-3xl font-semibold mb-10">The Business Innovation You Selected</p>
          {precedents.map((v) => {
            return (
              <div key={v.pId + 'list'} className="bg-white rounded-3xl shadow-md space-y-4 p-10">
                <div className="flex space-x-6">
                  <div className="h-14 w-14 rounded-sm overflow-hidden flex-shrink-0">
                    <img className="w-full h-full object-contain" src={`${s3FileUrl}${v.logo?.url}`} alt={v.actor} />
                  </div>
                  <div className="">
                    <p className="font-semibold text-lg">{v.precedentTitle}</p>
                    <p className="font-semibold text-sm text-gray-500">{v.actor}</p>
                  </div>
                </div>
                <div className="space-y-2">
                  <p className="font-semibold">Background</p>
                  <p className="text-sm font-normal text-black">{v.background}</p>
                </div>

                <div className="space-y-2">
                  <p className="font-semibold">Key Insights</p>
                  {v.insights &&
                    v.insights.map((insight, i) => (
                      <div key={i} className="font-medium space-y-2">
                        <p>{insight.bold}</p>
                        <p className="text-sm font-normal text-black">{insight.text}</p>
                      </div>
                    ))}
                </div>

                <div className="space-y-2">
                  <p className="font-semibold">Key Outcomes</p>
                  {v.outcomes &&
                    v.outcomes.map((outcome, i) => (
                      <div key={i} className="font-medium">
                        <p>{outcome.bold}</p>
                        <p className="text-sm font-normal text-black">{outcome.text}</p>
                      </div>
                    ))}
                </div>
              </div>
            );
          })}

          <div className="space-y-5">
            <p className="text-primary-400 text-3xl font-semibold">LUCY™ IDEAS REPORT</p>
            <div className="bg-white rounded-3xl shadow-md space-y-4 p-10">
              <div className="grid md:grid-cols-2 grid-col-1 md:gap-x-16 gap-y-8">
                {IdeasData.map((v, i) => (
                  <div key={i} className="flex space-x-5">
                    <div className="flex-shrink-0">
                      <img className="rounded-full" src={v.image} alt={v.name} />
                    </div>
                    <div className="">
                      <p className="text-primary-400 font-semibold italic mb-2">{v.title}</p>
                      <p className="text-primary-400">{v.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportPage;
