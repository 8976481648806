import axios from 'axios';
// import { loadStripe } from '@stripe/stripe-js';

export const config = {
  S3: {
    file: {
      bucket: 'lucy-resources', //REQUIRED -  Amazon S3 bucket name
      region: 'us-east-1', //OPTIONAL -  Amazon service region
    },
  },
  // stripe: {
  //   PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
  // },
};
export const API_ENDPOINT_LAMBDA = 'https://5libygmg6j.execute-api.us-east-1.amazonaws.com/dev';
export const s3FileUrl = 'https://lucy-resources.s3.amazonaws.com/';

// export const stripePromise = loadStripe(config.stripe.PUBLISHABLE_KEY);

export const LucyApi = axios.create({
  baseURL: API_ENDPOINT_LAMBDA,
  headers: {
    'Content-type': 'application/json',
  },
});
