import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IPrecedentByQuestionsResponse } from '../../types';
import { QUESTION_TYPES } from './Types';

export interface IQuestion {
  tId: number;
  tTitle: string;
  parentId: number;
  tDescription: string;
  iconname?: string;
  weight: number;
}

interface IQuestionReducer {
  MC?: IQuestion['tId'];
  DC?: IQuestion[];
  DA?: IQuestion[];
  precedents?: IPrecedentByQuestionsResponse[];
  selectedPrecedent?: IPrecedentByQuestionsResponse;
}

const InitialState: IQuestionReducer = {};

const addMc = (state: IQuestionReducer, action: any): IQuestionReducer => {
  return {
    ...state,
    MC: action.payload,
  };
};
const addDc = (state: IQuestionReducer, action: any): IQuestionReducer => {
  return {
    ...state,
    DC: action.payload,
    DA: undefined,
  };
};
const addDa = (state: IQuestionReducer, action: any): IQuestionReducer => {
  return {
    ...state,
    DA: action.payload,
  };
};
const addPrecedents = (state: IQuestionReducer, action: any): IQuestionReducer => {
  return {
    ...state,
    precedents: action.payload,
  };
};
const selectPrecedent = (state: IQuestionReducer, action: any): IQuestionReducer => {
  return {
    ...state,
    selectedPrecedent: action.payload,
  };
};

const Reducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case QUESTION_TYPES.ADD_MC:
      return addMc(state, action);
    case QUESTION_TYPES.ADD_DC:
      return addDc(state, action);
    case QUESTION_TYPES.ADD_DA:
      return addDa(state, action);
    case QUESTION_TYPES.ADD_PRECEDENTS:
      return addPrecedents(state, action);
    case QUESTION_TYPES.SELECT_PRECEDENT:
      return selectPrecedent(state, action);
    case QUESTION_TYPES.RESET_QUESTIONS:
      return {
        ...InitialState,
      };
    default:
      return state;
  }
};

// persistor for QuestionReducer
const persistConfig = {
  key: 'questionReducer',
  storage: storage,
};

export const questionReducer = persistReducer(persistConfig, Reducer);
